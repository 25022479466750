<template>
  <div style="width: 100%">
    <Drawer
      title="用户详情"
      :closable="false"
      width="1100"
      scrollable
      v-model="modals"
      @on-visible-change="draChange">
      <div class="usersInfo">
        <div class="acea-row head">
          <div class="avatar l-m-r15"><img :src="psInfo.avatar" /></div>
          <div class="dashboard-workplace-header-tip">
            <p class="dashboard-workplace-header-tip-title" v-text="psInfo.nickname || '-'"></p>
            <div class="dashboard-workplace-header-tip-desc">
              <span class="dashboard-workplace-header-tip-desc-sp" v-for="(item, index) in detailsData" :key="index">
                {{ item.title + '：' + item.value }}
              </span>
            </div>
          </div>
          <Affix :offset-top="60" use-capture>
            <div class="edit-btn">
              <Button v-if="!isEdit" type="primary" @click="editUsersInfo">编辑</Button>
              <Button v-if="isEdit" class="l-m-r20" @click="editUsersInfo">取消</Button>
              <Button v-if="isEdit" type="primary" @click="usersInfoSave">保存</Button>
            </div>
          </Affix>
        </div>

        <Row type="flex" justify="space-between" class="l-m-t25">
          <Col span="24">
            <Tabs class="l-m-b20" :value="activeName" @on-click="changeType">
              <TabPane :name="item.val" v-for="(item, index) in navList" :key="index" :label="item.label" :disabled="item.display"></TabPane>
            </Tabs>
          </Col>
        </Row>

        <Col span="24">
          <template v-if="activeName === 'users'">
            <usersEditForm ref="editForm" :userId="userId" @success="getUsersDetails(userId)" v-if="isEdit"></usersEditForm>
            <usersInfo :ps-info="psInfo" v-else></usersInfo>
          </template>
          <template v-else>
            <Table
              :columns="columns"
              :data="userLists"
              max-height="400"
              ref="table"
              :loading="loading"
              no-userFrom-text="暂无数据"
              no-filtered-userFrom-text="暂无筛选结果">
              <template slot-scope="{ row }" slot="number">
                <div :class="row.pm ? 'plusColor' : 'reduceColor'">
                  {{ row.pm ? '+' + row.number : '-' + row.number }}
                </div>
              </template>
            </Table>
            <div class="acea-row row-right l-m-t10">
              <Page
                :total="total"
                :current="userFrom.page"
                show-elevator
                show-total
                @on-change="handlePageChange"
                :page-size="userFrom.limit"/>
            </div>
          </template>
        </Col>
      </div>

      <Spin size="large" fix v-if="spinShow"></Spin>
    </Drawer>
  </div>
</template>

<script>
import {
  getUsersDetailsApi,
  getTabInfoApi
} from '@/api/users'
import UsersInfo from './UsersInfo.vue'
import UsersEditForm from './UsersEditForm.vue'

export default {
  name: 'UsersDetails',
  components: {
    UsersInfo,
    UsersEditForm
  },
  data () {
    return {
      // 抽屉显示隐藏
      modals: false,
      // 用户信息
      psInfo: {},
      // 是否编辑
      isEdit: false,
      // 类型
      activeName: 'users',
      navList: [
        { val: 'users', label: '用户信息', display: false },
        { val: 'order', label: '消费记录', display: false },
        { val: 'integral', label: '积分明细', display: false },
        { val: 'sign', label: '签到记录', display: false },
        { val: 'coupon', label: '持有优惠券', display: false },
        { val: 'balance_change', label: '余额变动', display: true },
        { val: 'spread', label: '好友关系', display: true }
      ],
      // 用户详情数据
      detailsData: [],
      // 用户单前表单
      userFrom: {
        type: 'order',
        page: 1, // 当前页
        limit: 20 // 每页显示条数
      },
      // 条数
      total: 0,
      // 当前用户ID
      userId: 0,
      // 表格数据
      columns: [],
      userLists: [],
      loading: false,
      // 加载
      spinShow: false
    }
  },
  mounted () {
    this.getUsersDetails(1)
  },
  methods: {
    // 获取用户详情
    getUsersDetails (id) {
      this.activeName = 'users'
      this.userId = id
      this.spinShow = true
      this.isEdit = false
      getUsersDetailsApi(id).then(async res => {
        let data = res.data
        this.detailsData = data.headerList
        this.psInfo = data.ps_info
        this.spinShow = false
      }).catch((res) => {
        this.spinShow = false
        this.$Message.error(res.msg)
      })
    },
    draChange (status) {
      if (!status) {
        this.isEdit = false
      }
    },
    // 编辑用户信息
    editUsersInfo () {
      this.activeName = 'users'
      this.isEdit = !this.isEdit
    },
    // 保存用户信息
    usersInfoSave () {
      this.$refs.editForm.saveUsersInfo()
    },
    // tab选项
    changeType (name) {
      this.loading = true
      this.userFrom.type = name
      this.activeName = name
      this.isEdit = false
      if (name === 'users') return
      if (this.userFrom.type === '') {
        this.userFrom.type = 'order'
      }
      let data = {
        id: this.userId,
        datas: this.userFrom
      }
      getTabInfoApi(data).then(res => {
        let data = res.data
        this.userLists = data.list
        this.total = data.count
        switch (this.userFrom.type) {
          case 'order':
            this.columns = [
              { title: '订单ID', key: 'order_id', minWidth: 160 },
              { title: '收货人', key: 'real_name', minWidth: 100 },
              { title: '商品数量', key: 'total_num', minWidth: 90 },
              { title: '实付金额', key: 'pay_price', minWidth: 120 },
              { title: '交易完成时间', key: 'pay_time', minWidth: 120 }
            ]
            break
          case 'integral':
            this.columns = [
              { title: '来源/用途', key: 'title', minWidth: 120 },
              { title: '积分变化', key: 'number', minWidth: 120 },
              { title: '变化后积分', key: 'balance', minWidth: 120 },
              { title: '日期', key: 'add_time', minWidth: 120 },
              { title: '备注', key: 'mark', minWidth: 120 }
            ]
            break
          case 'sign':
            this.columns = [
              { title: '动作', key: 'title', minWidth: 120 },
              { title: '获得积分', key: 'number', minWidth: 120 },
              { title: '签到时间', key: 'add_time', minWidth: 120 },
              { title: '备注', key: 'mark', minWidth: 120 }
            ]
            break
          case 'coupon':
            this.columns = [
              { title: '优惠券名称', key: 'coupon_title', minWidth: 120 },
              { title: '面值', key: 'coupon_price', minWidth: 120 },
              { title: '有效期(天)', key: 'coupon_time', minWidth: 120 },
              { title: '兑换时间', key: '_add_time', minWidth: 120 }
            ]
            break
          case 'balance_change':
            this.columns = [
              { title: '动作', key: 'title', minWidth: 120 },
              { title: '余额变动', slot: 'number', minWidth: 120 },
              { title: '当前余额', key: 'balance', minWidth: 120 },
              { title: '创建时间', key: 'add_time', minWidth: 120 },
              { title: '备注', key: 'mark', minWidth: 120 }
            ]
            break
          default:
            this.columns = [
              { title: 'ID', key: 'uid', minWidth: 120 },
              { title: '昵称', key: 'nickname', minWidth: 120 },
              { title: '等级', key: 'type', minWidth: 120 },
              { title: '加入时间', key: 'add_time', minWidth: 120 }
            ]
        }
        this.loading = false
      }).catch()
    },
    handlePageChange (index) {
      this.userFrom.page = index
      this.changeType(this.userFrom.type)
    }
  }
}
</script>

<style scoped lang="less">
.usersInfo {
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.head {
  position: relative;
  .edit-btn {
    position: absolute;
    right: 10px;
    top: 0px;
    display: flex;
    align-items: center;
  }
}
.dashboard-workplace {
  &-header {
    &-avatar {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-right: 16px;
      font-weight: 600;
    }

    &-tip {
      width: 82%;
      display: inline-block;
      vertical-align: middle;

      &-title {
        font-size: 13px;
        color: #000000;
        margin-bottom: 12px;
      }

      &-desc {
        &-sp {
          width: 33.33%;
          color: #17233d;
          font-size: 13px;
          display: inline-block;
        }
      }
    }

    &-extra {
      .ivu-col {
        p {
          text-align: right;
        }

        p:first-child {
          span:first-child {
            margin-right: 4px;
          }

          span:last-child {
            color: #808695;
          }
        }

        p:last-child {
          font-size: 22px;
        }
      }
    }
  }
}
.user_menu /deep/ .ivu-menu {
  width: 100% !important;
}
</style>
